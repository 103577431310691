<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="form">
    <form class="form" id="branch_create" @submit.prevent="handleSubmit(onSubmit)">
      <div class="card card-custom">
        <!--begin::Header-->
        <div class="card-header">
          <div class="card-title">
            <h3 class="card-label">
              Sửa tên văn phòng
            </h3>
          </div>
          <div class="card-toolbar">
            <router-link
                class="font-weight-bold font-size-3  btn btn-secondary"
                :to="{ name: 'office-index' }"
            ><i class="fas fa-angle-double-left"></i> Quay lại
            </router-link>
          </div>
        </div>
        <!--end::Header-->
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Tên chi nhánh</label>
                <input type="text" ref="name" name="name" class="form-control" v-model="branch.name" placeholder="Tên chi nhánh" :class="classes" disabled>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label>Tên văn phòng</label>
                <ValidationProvider vid="name_alias" name="Tên văn phòng" rules="" v-slot="{ errors,classes }">
                  <input type="text" ref="name_alias" name="name_alias" class="form-control" v-model="branch.name_alias" placeholder="Tên văn phòng" :class="classes">
                  <div class="fv-plugins-message-container">
                    <div data-field="Tên văn phòng" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}</div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
        <!--Thông tin cấp trên-->
        <div class="separator separator-dashed my-5"></div>
        <div class="card-header">
          <div class="card-title align-items-start flex-column">
            <button type="submit" class="btn btn-success mr-2">
              Cập nhật
            </button>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>

</template>
<script>
import {mapGetters,} from "vuex";
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {UPDATE_BRANCH, GET_BRANCH_BY_ID} from "../../../core/services/store/center/branch.module";

export default {
  components: {},
  name: "BranchEdit",
  data() {
    return {
      branch: {
        name: '',
        name_alias: ''
      },
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tên văn phòng", route: 'office-index'},
      {title: "Sửa tên văn phòng"}
    ]);
    this.getBranch()
  },
  computed: {
    ...mapGetters(["errorsAccountType"]),
  },
  methods: {
    getBranch() {
      let id = this.$route.params.id;
      this.$store.dispatch(GET_BRANCH_BY_ID, id).then((res) => {
        if (!res.error) {
          this.branch = res.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    onSubmit() {
      this.$store.dispatch(UPDATE_BRANCH, this.branch).then((res) => {
        if (res.error) {
          this.$bvToast.toast(res.message, {
            title: 'Sửa tên văn phòng',
            variant: 'danger',
            solid: true
          });
        } else {
          this.$router.push({name: "office-index"}).then(() => {
            this.$bvToast.toast(res.message, {
              title: 'Sửa tên văn phòng',
              variant: 'success',
              solid: true
            });
          })
        }
      }).catch((e) => {
        if (e.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.data.data.message_validate_form);
        }
      });
    },
  }
}
</script>
